<template>
  <div class="all-tickets">
    <AdminTable title="All Tickets" :list="ticketList" :fields="fields" @update-list="update"
    @open-modal="openModal" @edit="editTicket"/>
    <TicketModal />
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import TicketModal from '@/components/admin/tickets/TicketModal'
import { ticketService } from '@/services/admin'

export default {
  name: 'AdminAllTickets',
  components: {
    AdminTable,
    TicketModal,
  },
  data() {
    return {
      ticketList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'itemName', label: 'Item Name' },
        { key: 'itemCode', label: 'Item Code' },
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'userFirstname', label: 'Firstname' },
        { key: 'userLastname', label: 'Lastname' },
        { key: 'openDate', label: 'Open Date', type: 'date' },
        { key: 'closedDate', label: 'Closed Date', type: 'date' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'show'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    editTicket(ticket) {
      this.$store.commit('adminTicketStore/setTicket', ticket)
      this.$router.push({ name: 'edit-ticket' })
    },
    openModal(ticket) {
      this.$store.commit('adminTicketStore/setTicket', ticket)
      this.$root.$emit('bv::show::modal', 'admin-ticket-modal')
    },
    async update() {
      this.loading = true
      this.ticketList = await ticketService.getTickets()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>