<template>
  <div class="admin-table">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="admin-title mb-0">{{ $t(title) }}</h1>
      <div class="d-flex">
        <RFButton label="Update" size="small" background="#24425B" @on-click="$emit('update-list')"/>
        <RFButton v-if="availabilityButton" label="Show availability" size="small" background="#24425B"
                  @on-click="$emit('show-availability')"/>
        <RFButton v-if="manageAvailabilityButton" label="Manage availability" size="small" background="#24425B"
                  @on-click="$emit('manage-availability')"/>
        <RFButton v-if="accommodationTypesButton" label="Show accommodation types" size="small" background="#24425B"
                  @on-click="$emit('show-accommodation-types')"/>
        <RFButton v-if="roomsButton" label="Show rooms" size="small" background="#24425B"
                  @on-click="$emit('show-rooms')"/>
        <JsonCSV v-if="exportButton"
                 :data="list"
                 :fields="getFields()"
                 :name="getCsvName()">
          <RFButton label="Export to CSV" size="small" background="#24425B"/>
        </JsonCSV>
      </div>
    </div>

    <b-table class="mt-5" responsive hover sticky-header :items="list" :fields="fields" :per-page="perPage"
             :current-page="currentPage" v-if="list.length && !loading">
      <template v-slot:head()="data">
        <div class="table-label">
          <template v-if="data.field.flag">
            <flag :squared="false" :iso="data.field.flag"/>
          </template>
          {{ data.label }}
        </div>
      </template>

      <template v-slot:cell()="data">
        <div class="table-data">
          <template v-if="data.field.key === 'status'">
            <div class="status" :class="{
              new: data.value === userStatus.NEW || data.value === reservationStatus.NEW,
              registered: data.value === userStatus.REGISTERED,
              'profile-complete': data.value === userStatus.PROFILE_COMPLETE,
              'check-document': data.value === userStatus.CHECK_DOCUMENT,
              'in-progress': data.value === ticketStatus.IN_PROGRESS,
              closed: data.value === ticketStatus.CLOSED,
              verified: data.value === userStatus.VERIFIED,
              refused: data.value === reservationStatus.REFUSED,
              accepted: data.value === reservationStatus.ACCEPTED,
              completed: data.value === reservationStatus.COMPLETED,
              active: data.value === reservationStatus.ACTIVE,
              canceled: data.value === reservationStatus.CANCELED,
              ok: data.value === paymentStatus.OK,
              ko: data.value === paymentStatus.KO,
              scheduled: data.value === paymentStatus.SCHEDULED,
              pending: data.value === paymentStatus.PENDING,
            }">{{ data.value | replaceUnderscore | toUpperCase }}
            </div>
          </template>
          <template v-else-if="data.field.type === 'date'">
            {{ data.value | formatDate }}
          </template>
          <template v-else-if="data.field.key === 'paymentType'">
            <span v-if="data.value === 'CC'">{{ $t('Credit Card') }}</span>
            <span v-else>{{ $t('Bank Transfer') }}</span>
          </template>
          <template v-else-if="data.field.type === 'cash'">
            &euro; {{ data.value }}
          </template>
          <template v-else-if="data.field.type === 'boolean'">
            <FontAwesomeIcon :icon="['fas', 'check']" :color="'#5CB85C'" v-if="data.value"/>
          </template>
          <template v-else-if="data.field.type === 'actions'">
            <div class="d-inline" v-for="(action, index) in data.field.actions" :key="`action-${index}`">
              <FontAwesomeIcon v-if="action === 'add'" class="cursor-pointer rf-success-color mx-1"
                               :icon="['fas', 'plus']" @click="$emit('add-related-item', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Add"/>
              <FontAwesomeIcon v-else-if="action === 'view'" class="cursor-pointer rf-blue-color mx-1"
                               :icon="['fas', 'eye']" @click="$emit('show-related-items', data.item)"
                               data-toggle="tooltip" data-placement="top" title="View"/>
              <FontAwesomeIcon v-else-if="action === 'edit' && data.item.status !== 'CANCELED'"
                               class="cursor-pointer mx-1" :icon="['fas', 'pen']" @click="$emit('edit', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Edit"/>
              <FontAwesomeIcon v-else-if="action === 'show'" class="cursor-pointer mx-1" :icon="['fas', 'search']"
                               :id="data" @click.stop="$emit('open-modal', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Show more"/>
              <!-- <FontAwesomeIcon v-else-if="action === 'calendar'" class="cursor-pointer mx-1" :icon="['fas', 'calendar']"
                               @click.stop="$emit('calendar', data.item)"/> -->
              <FontAwesomeIcon v-else-if="action === 'download' && data.item.documents !== null"
                               class="cursor-pointer mx-1" :icon="['fas', 'download']"
                               @click.stop="$emit('download', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Download contract"/>
              <template v-else-if="action === 'delete'">

                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash']" color="#FF5A5F"
                                 @click="selectItem(data.item)"
                                 v-if="!roomsAccommodationTypes && ((selectedItem && selectedItem.id !== data.item.id) || !confirm) && !data.item.deleted"
                                 data-toggle="tooltip" data-placement="top" title="Delete"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash-restore']" color="#24425B"
                                 @click="selectItem(data.item)"
                                 v-if="!roomsAccommodationTypes && data.item.deleted && !confirm"
                                 data-toggle="tooltip" data-placement="top" title="Restore"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C"
                                 @click="data.item.deleted ? restoreItem() : deleteItem()"
                                 v-if="!roomsAccommodationTypes && confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F"
                                 @click="confirm = false"
                                 v-if="!roomsAccommodationTypes && confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>

                <!-- Delete functionality for rooms accommodation types-->
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash']" color="#FF5A5F"
                                 @click="selectRoomAccommodationTypeItem(data.item,true)"
                                 v-if="roomsAccommodationTypes && ((selectedItem && selectedItem.id !== data.item.id) || !confirm)"
                                 data-toggle="tooltip" data-placement="top"
                                 title="Delete rooms with this accommodation type"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash-restore']" color="#24425B"
                                 @click="selectRoomAccommodationTypeItem(data.item,false)"
                                 v-if="roomsAccommodationTypes && !confirm"
                                 data-toggle="tooltip" data-placement="top"
                                 title="Restore rooms with this accommodation type"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C"
                                 @click="updateAccommodationTypeRooms()"
                                 v-if="roomsAccommodationTypes && confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F"
                                 @click="confirm = false"
                                 v-if="roomsAccommodationTypes && confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>

              </template>
              <template
                v-else-if="action === 'bt' && data.item.status === 'ACCEPTED' && data.item.payments.length === 0">
                <FontAwesomeIcon class="cursor-pointer rf-blue-color mx-1" :icon="['fas', 'money-bill']"
                                 @click="selectItem(data.item)"
                                 data-toggle="tooltip" data-placement="top"
                                 title="Set bank transfer as payment method"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C"
                                 @click="setBankTransfer()"
                                 v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F"
                                 @click="confirm = false"
                                 v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>
              </template>
            </div>
          </template>
          <template v-else>{{ data.value }}</template>
        </div>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-if="!loading && list.length > perPage"
      v-model="currentPage"
      :total-rows="list.length"
      :per-page="perPage"/>
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30"/>
      {{ $t('Uploading...') }}
    </div>
    <div class="empty-list" v-if="!loading && !list.length">
      {{ $t('There are no items.') }}
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import JsonCSV from 'vue-json-csv'
import {formatDate, replaceUnderscore, toUpperCase} from '@/helpers/filters'

import {paymentStatus, reservationStatus, ticketStatus, userStatus} from '@/enums'

export default {
  name: 'AdminTable',
  components: {
    RFButton,
    RFLoader,
    JsonCSV
  },
  props: {
    list: Array,
    fields: Array,
    toExportFields: {
      type: Array,
      default: null,
    },
    title: String,
    loading: Boolean,
    type: String,
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    hideRelatedItems: {
      type: Boolean,
      default: false,
    },
    hideOpenModal: {
      type: Boolean,
      default: false,
    },
    availabilityButton: {
      type: Boolean,
      default: false,
    },
    //RF-87
    manageAvailabilityButton: {
      type: Boolean,
      default: false,
    },
    exportButton: {
      type: Boolean,
      default: false,
    },
    accommodationTypesButton: {
      type: Boolean,
      default: false,
    },
    roomsButton: {
      type: Boolean,
      default: false,
    },
    roomsAccommodationTypes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userStatus,
      reservationStatus,
      paymentStatus,
      ticketStatus,

      selectedItem: null,
      confirm: false,
      deleteAccommodationTypeRooms: false,
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.confirm = true
    },
    deleteItem() {
      this.confirm = false
      this.$emit('delete', this.selectedItem)
    },
    restoreItem(item) {
      this.confirm = false
      this.$emit('restore', this.selectedItem)
    },
    getFields() {
      return this.toExportFields ? this.toExportFields : this.fields.map(f => f.key)
    },
    getCsvName() {
      return `${this.title.replaceAll(' ', '')}-${new Date().toISOString()}.csv`
    },
    setBankTransfer() {
      this.confirm = false
      this.$emit('set-bank-transfer', this.selectedItem)
    },
    selectRoomAccommodationTypeItem(item, deletingAccommodationTypeRooms) {
      this.selectedItem = item
      this.confirm = true
      this.deleteAccommodationTypeRooms = deletingAccommodationTypeRooms
    },
    updateAccommodationTypeRooms() {
      this.confirm = false
      this.$emit('update-accommodation-type-rooms', this.selectedItem, this.deleteAccommodationTypeRooms)
    },
  },
  filters: {
    formatDate,
    replaceUnderscore,
    toUpperCase
  }
}
</script>

<style lang="scss" scoped>


.admin-table {
  .table-label {
    @include font-style($montserrat-bold, 'medium', $font-12);
  }
}
</style>

