<template>
  <RFModal :modalId="modalId" title="Ticket" hide-footer>
    <b-row>
      <b-col>
        <div class="switch show-messages" @click="showMessages = true" v-if="!showMessages">{{$t('Show messages')}}</div>
        <div class="switch show-images" @click="showMessages = false" v-else>{{$t('Show images')}}</div>
      </b-col>
    </b-row>
    <div class="ticket-messages" v-show="showMessages" ref="messages">
      <div class="message-container" :class="{ admin: message.isAdmin }" v-for="(message, index) in ticket.messages" :key="`ticket-message-${index}`">
        <div class="message mb-2">
          {{message.content}}
          <div class="date-time">{{message.creationDateTime | formatDateTime}}</div>
        </div>
      </div>
    </div>
    <ValidationObserver ref="validator" v-if="showMessages">
      <form @submit.prevent class="mt-3">
        <b-row>
          <b-col>
            <RFValidation name="Message" rules="required" mode="passive">
              <RFTextArea label="Message" v-model="message.content" />
            </RFValidation>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton label="" :icon="['fas', 'paper-plane']" @on-click="sendMessage" v-if="!loading"/>
            <RFLoader :size="30" color="#24425B" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
    <b-row class="ticket-images" v-else>
      <b-col cols="3" class="ticket-image" v-for="(image, index) in ticket.images" :key="`image-${index}`">
        <img class="w-100" :src="image.pathname | getAsset" />
      </b-col>
    </b-row>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFValidation from '@/components/forms/RFValidation'
import RFTextArea from '@/components/forms/RFTextArea'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { ticketService } from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AdminTicketModal',
  components: {
    RFModal,
    RFValidation,
    RFTextArea,
    RFButton,
    RFLoader,
  },
  data() {
    return {
      modalId: 'admin-ticket-modal',
      showMessages: false,
      message: {
        content: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      ticket: state => state.adminTicketStore.ticket,
    })
  },
  watch: {
    ticket: {
      deep: true,
      handler() {
        this.scrollMessages()
      }
    },
    showMessages: {
      handler() {
        this.scrollMessages()
      }
    }
  },
  methods: {
    scrollMessages() {
      window.setTimeout(() => {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
      }, 100)
    },
    async sendMessage() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const { data: message, status } = await ticketService.addMessage(this.ticket.id, this.message)

        if (status === 200) {
          this.ticket.messages.push(message)
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add ticket message`))
        }

        this.message.content = null
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  @include font-style($montserrat, 'medium', $font-14);
  color: $success;
  cursor: pointer;
  margin-bottom: 20px;
}
.ticket-messages {
  height: 55vh;
  max-height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;

  .message-container {
    margin: 0 10px;

    &.admin {
      display: flex;
      justify-content: flex-end;

      .message {
        background-color: $dark-blue;
      }
    }
    .message {
      @include font-style($montserrat, 'medium', $font-14);
      background-color: $green;
      border-radius: 5px;
      color: $white;
      padding: 5px 10px;
      width: fit-content;
      max-width: 50%;
      word-break: break-all;
      .date-time {
        @include font-style($montserrat-light, 'light', $font-14);
        text-align: right;
      }
    }
  }
}
</style>